/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: script.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 -----
*/
// Componentes locais
import botoesAcao from "@/components/botoesAcao";
import ferramentas from "@/components/ferramentas/";
import dayjs from "dayjs";
import * as wjcCore from "@grapecity/wijmo";

// Imports wijmo
import { mapState } from "vuex";
import { faSmileWink } from "@fortawesome/free-regular-svg-icons";
export default {
  data() {
    return {
      keyFlexGrid: 0,
      flex: {},
      menuRetirar: false,
      showPanel: false,
      // produto para ir para o drawer
      produtoSelecionado: [],
      nomeAbaSelecionada: "",
      abas: [],
      produtoSelecionadoObj: {},
      editandoDrawer: false,
      // eslint-disable-next-line vue/no-reserved-keys
      _defaultRowHeight: 0,
      selector: null,
      imgProdSelecionado: "",
      abreDialog: false,
      //constants,
      modeloProdutos: "",
      selectedItems: [],
      grupoItems: [],
      secaoItems: [],
      depItems: [],
      colecItems: [],
      dados: [],
      camposEditarProduto: [
        {
          DIVISION: "",
          fields: [
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.nomeProduto"),
              contaCaracter: true,
              hint: "", // opcional
              cols: 12,
              maxLength: 100,
              required: true,
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              chave: "ce110_ds_prod",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.referencia"),
              contaCaracter: true,
              hint: "", // opcional
              cols: 12,
              maxLength: 60,
              required: false,
              chave: "ce110_cd_ref",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: "Class Fiscal",
              filtro: false,
              sp: "SP_CE360901020",
              opcoesTipo: [
                {
                  value: "NF100_CD_CLASS",
                  text: "class fiscal cod",
                  type: ["text"],
                },
                {
                  value: "NF100_DS_CLASS",
                  text: "class fiscal des",
                  type: ["text"],
                },
              ],
              retorno: [],
              adicionar: false,
              hint: "", // opcional
              cols: 4,
              required: false,
              chave: "ce110_cd_class",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "tel", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.sku"),
              contaCaracter: true,
              hint: "", // opcional
              cols: 5,
              required: true,
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              maxLength: 60,
              chave: "ce110_cd_sku",
            },
            {
              model: true,
              vuetify: "switch",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.situacao.situacNome"),
              items: [
                {
                  text: `${this.$t(
                    "TelaManProdutos.cadProdutos.situacao.situacNome",
                  )}: ${this.$t("TelaManProdutos.cadProdutos.situacao.ativo")}`,
                  value: true,
                },
                {
                  text: `${this.$t(
                    "TelaManProdutos.cadProdutos.situacao.situacNome",
                  )}: ${this.$t(
                    "TelaManProdutos.cadProdutos.situacao.inativo",
                  )}`,
                  value: false,
                },
              ],
              hint: "", // opcional
              cols: 3,
              required: true,
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              chave: "ce110_cd_situac_switch",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: "NCM",
              filtro: true,
              sp: "SP_CE360903010",
              opcoesTipo: [
                {
                  value: "NF730_CD_NPS",
                  text: "Código",
                  type: ["text"],
                },
                {
                  value: "NF730_DS_NPS",
                  text: "NCM DES",
                  type: ["text"],
                },
              ],
              adicionar: false,
              hint: "", // opcional
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              cols: 6,
              retorno: [],
              required: true,
              chave: "ce110_cd_ncm",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: "Nr. fabricante",
              filtro: true,
              sp: "SP_CE360901034",
              opcoesTipo: [
                {
                  value: "CE115_NR_PESSOA",
                  text: "Código",
                  type: ["text"],
                },
                {
                  value: "CE115_NM_RAZAO",
                  text: "Fabricante",
                  type: ["text"],
                },
              ],
              adicionar: false,
              hint: "", // opcional
              cols: 4,
              retorno: [],
              required: false,
              chave: "ce110_nr_fabric",
            },
            {
              model: "",
              vuetify: "textfield",
              readonly: true,
              type: "text", // principais: text, tel, password
              nome: "EXNCM",
              filtro: false,
              hint: "", // opcional
              cols: 2,
              retorno: [],
              required: false,
              chave: "ce110_cd_exncm",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.unidade.unidadeNome"),
              filtro: false,
              sp: "SP_CE360901021",
              opcoesTipo: [
                {
                  value: "NF722_CD_UNCOM",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.unidade.unidadeCod",
                  ),
                  type: ["text"],
                },
                {
                  value: "NF722_DS_UNCOM",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.unidade.unidadeCom",
                  ),
                  type: ["text"],
                },
              ],
              retorno: [],
              adicionar: false,
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              chave: "ce110_cd_uncom",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: this.$t(
                "TelaManProdutos.cadProdutos.departamento.departamentoNome",
              ),
              filtro: false,
              sp: "SP_CE360901003",
              opcoesTipo: [
                {
                  value: "CE175_CD_DEPTO",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.departamento.departamentoCod",
                  ),
                  type: ["text"],
                },
                {
                  value: "CE175_DS_DEPTO",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.departamento.departamentoNome",
                  ),
                  type: ["text"],
                },
              ],
              adicionar: true,
              hint: "", // opcional
              cols: 4,
              retorno: [],
              required: false,
              chave: "ce175_ds_depto",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.grupo.nomeGrupo"),
              filtro: false,
              sp: "SP_CE360901001",
              opcoesTipo: [
                {
                  value: "CE113_CD_GRP",
                  text: this.$t("TelaManProdutos.cadProdutos.grupo.codGrupo"),
                  type: ["text"],
                },
                {
                  value: "CE113_DS_GRP",
                  text: this.$t("TelaManProdutos.cadProdutos.grupo.nomeGrupo"),
                  type: ["text"],
                },
              ],
              retorno: [],
              adicionar: true,
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              chave: "ce113_ds_grp",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.categoria.categNome"),
              filtro: false,
              sp: "SP_CE360901025",
              opcoesTipo: [
                {
                  value: "CE177_CD_CATEG",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.categoria.categCod",
                  ),
                  type: ["text"],
                },
                {
                  value: "CE177_DS_CATEG",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.categoria.categDes",
                  ),
                  type: ["text"],
                },
              ],
              retorno: [],
              adicionar: true,
              hint: "", // opcional
              cols: 3,
              required: false,
              chave: "ce110_cd_categ",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.marca.marcaNome"),
              filtro: false,
              sp: "SP_CE360901031",
              opcoesTipo: [
                {
                  value: "CE179_CD_MARCA",
                  text: this.$t("TelaManProdutos.cadProdutos.marca.marcaCod"),
                  type: ["text"],
                },
                {
                  value: "CE179_DS_MARCA",
                  text: this.$t("TelaManProdutos.cadProdutos.marca.marcaNome"),
                  type: ["text"],
                },
              ],
              adicionar: true,
              hint: "", // opcional
              cols: 3,
              retorno: [],
              required: false,
              chave: "ce179_ds_marca",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.secao.secaoNome"),
              filtro: false,
              sp: "SP_CE360901002",
              opcoesTipo: [
                {
                  value: "CE176_CD_SECAO",
                  text: this.$t("TelaManProdutos.cadProdutos.secao.secaoCod"),
                  type: ["text"],
                },
                {
                  value: "CE176_DS_SECAO",
                  text: this.$t("TelaManProdutos.cadProdutos.secao.secaoNome"),
                  type: ["text"],
                },
              ],
              adicionar: true,
              hint: "", // opcional
              cols: 3,
              retorno: [],
              required: false,
              chave: "ce176_ds_secao",
            },
            {
              model: "",
              vuetify: "searchtype",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.colecao.colecaoNome"),
              filtro: false,
              sp: "SP_CE360901004",
              opcoesTipo: [
                {
                  value: "CE178_CD_COLEC",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.colecao.colecaoCod",
                  ),
                  type: ["text"],
                },
                {
                  value: "CE178_DS_COLEC",
                  text: this.$t(
                    "TelaManProdutos.cadProdutos.colecao.colecaoNome",
                  ),
                  type: ["text"],
                },
              ],
              adicionar: true,
              hint: "", // opcional
              cols: 3,
              retorno: [],
              required: false,
              chave: "ce178_ds_colec",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.valorComp"),
              hint: "", // opcional
              cols: 6,
              maxLength: 19,
              required: false,
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              chave: "ce110_vl_custo",
              money: {
                decimal: ",",
                thousands: "",
                prefix: "R$ ",
                suffix: "",
                precision: 2,
              },
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: this.$t("TelaManProdutos.cadProdutos.valorVend"),
              hint: "", // opcional
              cols: 6,
              maxLength: 19,
              required: false,
              rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
              chave: "ce110_vl_venda",
              money: {
                decimal: ",",
                thousands: "",
                prefix: "R$ ",
                suffix: "",
                precision: 2,
              },
            },
            // {
            //   model: "",
            //   vuetify: "text",
            //   type: "text", // principais: text, tel, password
            //   nome: this.$t("TelaManProdutos.cadProdutos.descProd"),
            //   cols: 12,
            //   required: false,
            //   rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
            //   chave: "ce106_ds_det",
            // },
            // {
            //   model: "",
            //   vuetify: "text",
            //   type: "text", // principais: text, tel, password
            //   nome: this.$t("TelaManProdutos.cadProdutos.espefTec"),
            //   cols: 12,
            //   required: false,
            //   rules: [(n) => !!n || this.$t("Notificacao.obrigatorio")],
            //   chave: "ce106_ds_esptec",
            // },
          ],
        },
      ],
      tabelaMobile:
        '<div class="item-header">{ce110_ds_prod}</div>' +
        '<div class="item-detail">Vlr. compra: {ce110_vl_custo}, Vlr. venda: {ce110_vl_venda}</div>' +
        '<div class="item-detail">Grupo: {ce113_ds_grp}, Dep: {ce175_ds_depto}, Seção: {ce176_ds_secao}, Coleção: {ce178_ds_colec}</div>',
      obrigatorios: [
        "SKU",
        "Referência",
        "Produto",
        "Situação",
        "Grupo",
        "Vlr. compra",
        "Vlr. venda",
        "Unidade",
      ],
      colunas: [
        {
          cabecalho: this.$t("ItensTabela.dtAtt"),
          larg: 100,
          valor: "ce110_dt_atusis",
          align: "right",
        },
        {
          cabecalho: this.$t("ItensTabela.dtCad"),
          larg: 100,
          valor: "ce110_dt_incsis",
          align: "right",
          format: "*",
        },
        {
          cabecalho: this.$t("ItensTabela.valorVen"),
          larg: 120,
          valor: "ce110_vl_venda",
          align: "right",
          format: "*",
        },
        {
          cabecalho: this.$t("ItensTabela.valorAnt"),
          larg: 110,
          valor: "ce110_vl_venant",
          align: "right",
          format: "*",
        },
        {
          cabecalho: this.$t("ItensTabela.valorCom"),
          larg: 150,
          valor: "ce110_vl_custo",
          align: "right",
          format: "*",
        },
        {
          cabecalho: this.$t("ItensTabela.origem"),
          larg: 100,
          valor: "nf724_ds_orig",
        },
        {
          cabecalho: this.$t("ItensTabela.cest"),
          larg: 90,
          valor: "ce110_cd_cest",
          align: "right",
        },
        {
          cabecalho: this.$t("ItensTabela.exncm"),
          larg: 110,
          valor: "ce110_cd_exncm",
        },
        {
          cabecalho: this.$t("ItensTabela.ncm"),
          larg: 85,
          valor: "ce110_cd_ncm",
        },
        {
          cabecalho: this.$t("ItensTabela.unidade"),
          larg: 110,
          valor: "ce110_cd_uncom",
        },
        {
          cabecalho: this.$t("ItensTabela.categoria"),
          larg: 120,
          valor: "ce177_ds_categ",
        },
        {
          cabecalho: this.$t("ItensTabela.marca"),
          larg: 100,
          valor: "ce179_ds_marca",
        },
        {
          cabecalho: this.$t("ItensTabela.grupo"),
          larg: 100,
          valor: "ce113_ds_grp",
        },
        {
          cabecalho: this.$t("ItensTabela.colecao"),
          larg: 100,
          valor: "ce178_ds_colec",
        },
        {
          cabecalho: this.$t("ItensTabela.secao"),
          larg: 100,
          valor: "ce176_ds_secao",
        },
        {
          cabecalho: this.$t("ItensTabela.departamento"),
          larg: 140,
          valor: "ce175_ds_depto",
        },
        {
          cabecalho: this.$t("ItensTabela.fabricante"),
          larg: 120,
          valor: "ce115_nm_razao",
          align: "right",
        },
        {
          cabecalho: this.$t("ItensTabela.situacao"),
          larg: 110,
          valor: "ce110_cd_situac_mdl",
        },
        {
          cabecalho: this.$t("ItensTabela.produto"),
          larg: 120,
          valor: "ce110_ds_prod",
        },
        {
          cabecalho: this.$t("ItensTabela.referencia"),
          larg: 115,
          valor: "ce110_cd_ref",
          align: "right",
        },
        {
          cabecalho: this.$t("ItensTabela.sku"),
          larg: 90,
          valor: "ce110_cd_sku",
          align: "right",
        },
        {
          cabecalho: "Cód. barras",
          larg: 130,
          valor: "ce119_cd_barras",
          align: "right",
          format: "d*",
        },
        /*         {
          cabecalho: 'Im. prod',
          larg: '*',
          valor:'ce107_im_arq[0].ce107_im_arq',
        }, */
      ],
      opcoesTipo: [
        {
          value: "CE110_CD_SKU",
          text: "SKU",
          type: ["text"],
        },
        {
          value: "CE119_CD_BARRAS",
          text: "Cód. barras",
          type: ["number"],
        },
        {
          value: "CE110_CD_REF",
          text: "Referência",
          type: ["text"],
        },
        {
          value: "CE110_DS_PROD",
          text: "Descrição do produto",
          type: ["text"],
        },
        {
          value: "CE110_CD_SITUAC",
          text: "Situação",
          type: ["select"],
          options: [
            { value: 1, text: "Ativo" },
            { value: 2, text: "Bloqueado" },
          ],
        },
        {
          value: "CE115_NM_RAZAO",
          text: "Nome do fabricante",
          type: ["text"],
        },
        {
          value: "CE110_CD_DEPTO",
          text: "Departamento",
          type: ["number"],
        },
        {
          value: "CE110_CD_SECAO",
          text: "Seção",
          type: ["number"],
        },
        {
          value: "CE110_CD_COLEC",
          text: "Coleção",
          type: ["number"],
        },
        {
          value: "CE113_DS_GRP",
          text: "Grupo",
          type: ["text"],
        },
        {
          value: "CE179_CD_MARCA",
          text: "Marca",
          type: ["number"],
        },
        {
          value: "CE177_CD_CATEG",
          text: "Categoria",
          type: ["number"],
        },
        {
          value: "CE110_CD_UNCOM",
          text: "Unidade",
          type: ["text"],
        },
        {
          value: "CE110_CD_NCM",
          text: "NCM",
          type: ["number"],
        },
        {
          value: "CE110_CD_EXNCM",
          text: "Ex. NCM",
          type: ["number"],
        },
        {
          value: "CE110_CD_ORIGEM",
          text: "Origem",
          type: ["number"],
        },
        {
          value: "CE110_VL_CUSTO",
          text: "Valor de compra",
          type: ["number"],
        },
        {
          value: "CE110_VL_VENDA",
          text: "Valor de venda",
          type: ["number"],
        },
        // {
        //   value: "CE110_DT_INCSIS",
        //   text: "Data de cadastro",
        //   type: ["calendar"],
        // },
        // {
        //   value: "CE110_DT_ATUSIS",
        //   text: "Data da última alteração",
        //   type: ["calendar"],
        // },
      ],

      itensFerramentas: [
        {
          text: this.$t("Ferramentas.ncm.ncmNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360903010",
            opcoesTipo: [
              {
                value: "NF730_CD_NPS",
                text: this.$t("Ferramentas.ncm.ncmCod"),
                type: ["text"],
              },
              {
                value: "NF730_DS_NPS",
                text: this.$t("Ferramentas.ncm.ncmDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.ncm.ncmNome"),
            adicionar: true,
            filtro: true,
            itens: false,
            objKey: "nf730_cd_nps",
          },
        },
        {
          text: this.$t("Ferramentas.classFiscal.classNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901020",
            opcoesTipo: [
              {
                value: "NF100_CD_CLASS",
                text: this.$t("Ferramentas.classFiscal.classCod"),
                type: ["text"],
              },
              {
                value: "NF100_DS_CLASS",
                text: this.$t("Ferramentas.classFiscal.classDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.classFiscal.classNome"),
            adicionar: false,
            filtro: false,
            itens: false,
            objKey: "nf100_cd_class",
          },
        },
        {
          text: this.$t("Ferramentas.situacao.situacNome"),
          value: {
            type: "autocomplete",
            SP: false,
            opcoesTipo: "ce110_cd_situac",
            nome: this.$t("Ferramentas.situacao.situacNome"),
            adicionar: false,
            filtro: false,
            itens: [
              { text: this.$t("Ferramentas.situacao.situacAtiv"), value: 1 },
              { text: this.$t("Ferramentas.situacao.situacBloq"), value: 2 },
            ],
            objKey: false,
          },
        },
        {
          text: this.$t("Ferramentas.categoria.categNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901025",
            opcoesTipo: [
              {
                value: "CE177_CD_CATEG",
                text: this.$t("Ferramentas.categoria.categCod"),
                type: ["text"],
              },
              {
                value: "CE177_DS_CATEG",
                text: this.$t("Ferramentas.categoria.categDes"),
                type: ["text"],
              },
            ],
            nome: "categ",
            adicionar: false,
            filtro: false,
            itens: false,
            objKey: "ce177_ds_categ",
          },
        },
        {
          text: this.$t("Ferramentas.descProduto"),
          value: {
            type: "textfield",
            SP: false,
            opcoesTipo: "ce110_ds_prod",
            nome: this.$t("Ferramentas.descProduto"),
            adicionar: false,
            filtro: false,
            itens: false,
            maxlength: 40,
            objKey: false,
          },
        },
        {
          text: this.$t("Ferramentas.origem.origemNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901022",
            opcoesTipo: [
              {
                value: "NF724_CD_ORIG",
                text: this.$t("Ferramentas.origem.origemCod"),
                type: ["text"],
              },
              {
                value: "NF724_DS_ORIG",
                text: this.$t("Ferramentas.origem.origemDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.origem.origemNome"),
            adicionar: false,
            filtro: false,
            itens: false,
            objKey: "nf724_cd_orig",
          },
        },
        {
          text: this.$t("Ferramentas.valorVen"),
          value: {
            type: "monetary",
            SP: false,
            opcoesTipo: "ce110_vl_venda",
            nome: this.$t("Ferramentas.valorVen"),
            adicionar: false,
            filtro: false,
            itens: false,
            objKey: false,
          },
        },
        {
          text: this.$t("Ferramentas.valorCom"),
          value: {
            type: "monetary",
            SP: false,
            opcoesTipo: "ce110_vl_custo",
            nome: this.$t("Ferramentas.valorCom"),
            adicionar: false,
            filtro: false,
            itens: false,
            objKey: false,
          },
        },
        {
          text: this.$t("Ferramentas.unidade.unidadeNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901021",
            opcoesTipo: [
              {
                value: "NF722_CD_UNCOM",
                text: this.$t("Ferramentas.unidade.unidadeCod"),
                type: ["text"],
              },
              {
                value: "NF722_DS_UNCOM",
                text: this.$t("Ferramentas.unidade.unidadeDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.unidade.unidadeNome"),
            adicionar: false,
            filtro: false,
            itens: false,
            objKey: "nf722_cd_uncom",
          },
        },
        {
          text: this.$t("Ferramentas.fornecedor.fornecNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901023",
            opcoesTipo: [
              {
                value: "CE115_NR_CNPJ",
                text: this.$t("Ferramentas.fornecedor.cnpj"),
                type: ["number"],
              },
              {
                value: "CE115_NR_PESSOA",
                text: this.$t("Ferramentas.fornecedor.nrPessoa"),
                type: ["number"],
              },
              {
                value: "CE115_NM_FANTAS",
                text: this.$t("Ferramentas.fornecedor.nmFantas"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.fornecedor.fornecNome"),
            adicionar: false,
            filtro: true,
            itens: false,
            objKey: "ce115_nm_fantas",
          },
        },
        {
          text: this.$t("Ferramentas.grupo.grupoNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901001",
            opcoesTipo: [
              {
                value: "CE113_CD_GRP",
                text: this.$t("Ferramentas.grupo.grupoCod"),
                type: ["text"],
              },
              {
                value: "CE113_DS_GRP",
                text: this.$t("Ferramentas.grupo.grupoDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.grupo.grupoNome"),
            adicionar: true,
            filtro: false,
            itens: false,
            objKey: "ce113_ds_grp",
          },
        },
        {
          text: this.$t("Ferramentas.secao.secaoNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901002",
            opcoesTipo: [
              {
                value: "CE176_CD_SECAO",
                text: this.$t("Ferramentas.secao.secaoCod"),
                type: ["text"],
              },
              {
                value: "CE176_DS_SECAO",
                text: this.$t("Ferramentas.secao.secaoDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.secao.secaoNome"),
            adicionar: true,
            filtro: false,
            itens: false,
            objKey: "ce176_ds_secao",
          },
        },
        {
          text: this.$t("Ferramentas.departamento.deptoNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901003",
            opcoesTipo: [
              {
                value: "CE175_CD_DEPTO",
                text: this.$t("Ferramentas.departamento.deptoCod"),
                type: ["text"],
              },
              {
                value: "CE175_DS_DEPTO",
                text: this.$t("Ferramentas.departamento.deptoDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.departamento.deptoNome"),
            adicionar: true,
            filtro: false,
            itens: false,
            objKey: "ce175_ds_depto",
          },
        },
        {
          text: this.$t("Ferramentas.colecao.colecaoNome"),
          value: {
            type: "searchtype",
            SP: "SP_CE360901004",
            opcoesTipo: [
              {
                value: "CE178_CD_COLEC",
                text: this.$t("Ferramentas.colecao.colecaoCod"),
                type: ["text"],
              },
              {
                value: "CE178_DS_COLEC",
                text: this.$t("Ferramentas.colecao.colecaoDes"),
                type: ["text"],
              },
            ],
            nome: this.$t("Ferramentas.colecao.colecaoNome"),
            adicionar: true,
            filtro: false,
            itens: false,
            objKey: "ce178_ds_colec",
          },
        },
      ],
    };
  },
  computed: {
    ...mapState(["novoItem"]),
    campoClass() {
      return this.camposEditarProduto[0].fields[2];
    },
    campoNcm() {
      return this.camposEditarProduto[0].fields[5];
    },
    campoFabric() {
      return this.camposEditarProduto[0].fields[6];
    },
    campoExncm() {
      return this.camposEditarProduto[0].fields[7];
    },
    campoUnidade() {
      return this.camposEditarProduto[0].fields[8];
    },
    campoDepart() {
      return this.camposEditarProduto[0].fields[9];
    },
    campoGrupo() {
      return this.camposEditarProduto[0].fields[10];
    },
    campoCateg() {
      return this.camposEditarProduto[0].fields[11];
    },
    campoMarca() {
      return this.camposEditarProduto[0].fields[12];
    },
    campoSecao() {
      return this.camposEditarProduto[0].fields[13];
    },
    campoColec() {
      return this.camposEditarProduto[0].fields[14];
    },
  },
  components: { ferramentas, botoesAcao },
  watch: {
    "campoClass.retorno": {
      handler() {
        this.campoClass.model = this.campoClass.retorno.nf100_ds_class;
      },
    },
    "campoNcm.retorno": {
      handler() {
        this.campoNcm.model = `${this.campoNcm.retorno.nf730_cd_nps} - ${this.campoNcm.retorno.nf730_ds_nps}`;
        this.campoExncm.model = this.campoNcm.retorno.nf730_cd_exncm;
      },
    },
    "campoFabric.retorno": {
      handler() {
        this.campoFabric.model = `${this.campoFabric.retorno.ce115_nr_pessoa} - ${this.campoFabric.retorno.ce115_nm_razao}`;
      },
    },
    "campoUnidade.retorno": {
      handler() {
        this.campoUnidade.model = this.campoUnidade.retorno.nf722_cd_uncom;
      },
    },
    "campoDepart.retorno": {
      handler() {
        this.campoDepart.model = this.campoDepart.retorno.ce175_ds_depto;
      },
    },
    "campoGrupo.retorno": {
      handler() {
        this.campoGrupo.model = this.campoGrupo.retorno.ce113_ds_grp;
      },
    },
    "campoCateg.retorno": {
      handler() {
        this.campoCateg.model = this.campoCateg.retorno.ce177_ds_categ;
      },
    },
    "campoMarca.retorno": {
      handler() {
        this.campoMarca.model = this.campoMarca.retorno.ce179_ds_marca;
      },
    },
    "campoSecao.retorno": {
      handler() {
        this.campoSecao.model = this.campoSecao.retorno.ce176_ds_secao;
      },
    },
    "campoColec.retorno": {
      handler() {
        this.campoColec.model = this.campoColec.retorno.ce178_ds_colec;
      },
    },
  },

  async mounted() {
    this.getAutocompletes();
    // this.colunasTabelaImportada.reverse();
    this.colunas.reverse();
  },
  methods: {
    initializeGrid(flexgrid) {
      this.flex = flexgrid;
    },
    isColumnReadonly(cabecalho) {
      return (
        cabecalho === "Grupo" ||
        cabecalho === "Departamento" ||
        cabecalho === "Seção" ||
        cabecalho === "Coleção" ||
        cabecalho === "Situação" ||
        cabecalho === "Im. prod"
      );
    },

    retornaNomesGrupos() {
      return this.grupoItems.map(
        (grupo) => `${grupo.ce113_cd_grp}-${grupo.ce113_ds_grp}`,
      );
    },
    retornaNomesSecao() {
      return this.secaoItems.map(
        (secao) => `${secao.ce176_cd_secao}-${secao.ce176_ds_secao}`,
      );
    },
    retornaNomesDep() {
      return this.depItems.map(
        (dep) => `${dep.ce175_cd_depto}-${dep.ce175_ds_depto}`,
      );
    },
    retornaNomesColec() {
      return this.colecItems.map(
        (colec) => `${colec.ce178_cd_colec}-${colec.ce178_ds_colec}`,
      );
    },
    alteraProdFerramentas(e) {
      this.dados = this.dados.map((dado) => {
        if (dado.isSelected) {
          if ("Itens com markup" in e) {
            // Recebe o dado somente com as alterações de markup
            dado = e["Itens com markup"].filter(
              (i) => i.ce110_cd_prod === dado.ce110_cd_prod,
            )[0];
          }
          const grupoMdl = e.situacao ? e.situacao : dado.ce110_cd_situac;
          return {
            // Altera de acordo com as outras alterações de outras linhas
            ...dado,
            ce110_ds_prod: e.descProduto ? e.descProduto : dado.ce110_ds_prod,
            ce110_cd_ncm: e.ncm ? e.ncm.nf730_cd_nps : dado.ce110_cd_ncm,
            ce110_cd_origem: e.origem
              ? e.origem.nf724_cd_orig
              : dado.ce110_cd_origem,
            ce110_cd_class: e["Class. fiscal"]
              ? e["Class. fiscal"].nf100_cd_class
              : dado.ce110_cd_class,
            ce110_cd_uncom: e.Unidade
              ? e.Unidade.nf722_cd_uncom
              : dado.ce110_cd_uncom,
            ce110_cd_situac: e.situacao
              ? e.situacao.ce110_cd_situac
              : dado.ce110_cd_situac,
            ce110_nr_fabric: e["Nr. fabricante"]
              ? e["Nr. fabricante"].ce115_nr_pessoa
              : dado.ce115_nr_pessoa,
            ce110_cd_categ: e.categ
              ? e.categ.ce177_cd_categ
              : dado.ce177_cd_categ,
            ce177_ds_categ: e.categ
              ? e.categ.ce177_ds_categ
              : dado.ce177_ds_categ,
            ce110_cd_marca: e.marca
              ? e.marca.ce179_cd_marca
              : dado.ce179_cd_marca,
            ce179_ds_marca: e.marca
              ? e.marca.ce179_ds_marca
              : dado.ce179_ds_marca,
            ce110_vl_custo: e["Vl. custo"]
              ? e["Vl. custo"]
              : dado.ce110_vl_custo,
            ce110_vl_venda: e["Vl. venda"]
              ? e["Vl. venda"]
              : dado.ce110_vl_venda,
            ce110_cd_grp: e.grupo ? e.grupo.ce113_cd_grp : dado.ce110_cd_grp,
            ce110_cd_depto: e.departamento
              ? e.departamento.ce175_cd_depto
              : dado.ce110_cd_depto,
            ce110_cd_secao: e.secao
              ? e.secao.ce176_cd_secao
              : dado.ce110_cd_secao,
            ce110_cd_colec: e.colecao
              ? e.colecao.ce178_cd_colec
              : dado.ce110_cd_colec,
            ce113_ds_grp: e.grupo ? e.grupo.ce113_ds_grp : dado.ce113_ds_grp,
            ce175_ds_depto: e.departamento
              ? e.departamento.ce175_ds_depto
              : dado.ce175_ds_depto,
            ce176_ds_secao: e.secao
              ? e.secao.ce176_ds_secao
              : dado.ce176_ds_secao,
            ce178_ds_colec: e.colecao
              ? e.colecao.ce178_ds_colec
              : dado.ce178_ds_colec,
            ce110_cd_situac_mdl: grupoMdl === 1 ? "Ativo" : "Bloqueado",
            ce113_ds_grp_mdl: e.grupo
              ? e.grupo.ce113_ds_grp_mdl
              : dado.ce113_ds_grp,
            ce175_ds_depto_mdl: e.departamento
              ? e.departamento.ce175_ds_depto
              : dado.ce175_ds_depto_mdl,
            ce176_ds_secao_mdl: e.secao
              ? e.secao.ce176_cd_secao
              : dado.ce176_ds_secao_mdl,
            ce178_ds_colec_mdl: e.colecao
              ? e.colecao.ce178_cd_colec
              : dado.ce178_ds_colec_mdl,
          };
        } else {
          return dado;
        }
      });
      this.keyFlexGrid++;
    },

    async mountObj(obj) {
      try {
        this.startLoader();
        const padroesIdb = await this.getSessionStorage("padroes");
        // console.log(padroesIdb);
        if (padroesIdb && padroesIdb.data) {
          const produtos = await this.$wso2.get(
            { functionName: `SP_CE360901000`, server: "DW", versao: "1" },
            padroesIdb.data.us11_nr_instpd,
            "36",
            ``,
            obj,
          );
          /* let produtos = await this.request({
            metodo: 'get',
            sp: "SP_CE360901000",
            params: obj.filtros[0] || obj,
          }); */
          this.getProdutos(produtos);
        }
      } catch {
        this.notification().error({erro: "Erro ao trazer o produto"});
      } finally {
        this.stopLoader();
      }
    },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro pode conter algum erro!"});
    },
    _updateGridLayout: function() {
      // show/hide columns
      let narrow = this.flex.hostElement.clientWidth < 600;
      this.flex.columns.forEach((col) => {
        col.visible = col.index == 1 ? narrow : !narrow;
      });
      this.showPanel = narrow;
      // make rows taller on phone layout
      this.flex.rows.defaultSize = this._defaultRowHeight * (narrow ? 3 : 1);
      // hide column headers on narrow layouts
      this.flex.headersVisibility = narrow
        ? this.wjcGrid.HeadersVisibility.None
        : this.wjcGrid.HeadersVisibility.All;
    },
    _commitEdit() {
      if (this._currentEditItem) {
        this.flex.columns.forEach((col) => {
          let input = this.flex.hostElement.querySelector("#" + col.binding);
          if (input) {
            let value = wjcCore.changeType(
              input.value,
              col.dataType,
              col.format,
            );
            if (wjcCore.getType(value) == col.dataType) {
              this._currentEditItem[col.binding] = value;
            }
          }
        });
        this.salvaEdicaoProdutosLinha();
      }
      this._currentEditItem = null;
      this.flex.invalidate();
    },
    _cancelEdit() {
      if (this._currentEditItem) {
        this._currentEditItem = null;
        this.flex.invalidate();
      }
    },
    generateSelectAttHTML(colBinding, initValue, values) {
      var html = `<select class="form-control"
              id="${colBinding}">
              <option> - </option>`;
      for (let i = 0; i <= values.length - 1; i++) {
        html += `<option ${initValue === values[i] ? "selected" : ""}>${
          values[i]
        }</option>`;
      }
      html += "</select>";
      return html;
    },
    async getProdutos(produtosDB) {
      try {
        this.startLoader();
        produtosDB = produtosDB ? produtosDB.database : null;
        if (produtosDB) {
          this.dados = produtosDB.map((p, i) => {
            let imgProd = JSON.parse(p.ce107_im_arq);
            imgProd = imgProd.map((img) => {
              return {
                ...img,
                ce107_im_arq:
                  img.ce107_im_arq !== null
                    ? "data:image/png;base64," + img.ce107_im_arq : null,
                    // : "data:image/png;base64," +
                    //   "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAn0lEQVQ4y73UuxECIRSF4T8wpIANLGH7MDTYAijAYEuyCEugBAqwAEPDDa6Rj5V77iwGHjKGbwaGA/Cf7PqWD1QmIFEwMS6Mb5Axlg3kI/OLqBjWTkTEBRFxQbQxF0RnEUATCRQJgE8CkDk4RIKROzf2DREgUTGMwunrXgQ4Nw16khId2utYomwBa7LKVdZadOzYT6LMGLXvIWeGH/+LBxZwp7hPm4eMAAAAAElFTkSuQmCC",
              };
            });
            return {
              ...p,
              isSelected: false,
              itemKey: i,
              ce110_vl_custo: this.formatCurrency(p.ce110_vl_custo),
              ce110_vl_venda: this.formatCurrency(p.ce110_vl_venda),
              ce110_vl_venant:
                p.ce110_vl_venant !== null
                  ? (p.ce110_vl_venant = this.formatCurrency(p.ce110_vl_venant))
                  : "",
              ce107_im_arq: imgProd,
              ce110_cd_situac_mdl:
                p.ce110_cd_situac === 1 ? "Ativo" : "Bloqueado",
              ce110_cd_situac_switch: p.ce110_cd_situac === 1 ? true : false,
              ce113_ds_grp_mdl: `${p.ce110_cd_grp ? p.ce110_cd_grp : ""}-${
                p.ce113_ds_grp ? p.ce113_ds_grp : ""
              }`,
              ce175_ds_depto_mdl: `${
                p.ce110_cd_depto ? p.ce110_cd_depto : ""
              }-${p.ce175_ds_depto ? p.ce175_ds_depto : ""}`,
              ce176_ds_secao_mdl: `${
                p.ce110_cd_secao ? p.ce110_cd_secao : ""
              }-${p.ce176_ds_secao ? p.ce176_ds_secao : ""}`,
              ce178_ds_colec_mdl: `${
                p.ce110_cd_colec ? p.ce110_cd_colec : ""
              }-${p.ce178_ds_colec ? p.ce178_ds_colec : ""}`,
              ce110_dt_incsis: dayjs(p.ce110_dt_incsis)
                .locale("pt-br")
                .format("DD/MM/YYYY HH:mm:ss"),
              ce110_dt_atusis: dayjs(p.ce110_dt_atusis)
                .locale("pt-br")
                .format("DD/MM/YYYY HH:mm:ss"),
            };
          });
        }
        const backup = this.dados.map((d) => {
          return { ...d };
        });
        const objAba = {
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${dayjs()
            .locale("pt-br")
            .format("DD/MM/YYYY")} - ${this.$t("TelaLojas.quantidade")}: ${
            this.dados.length
          }`,
          pesquisa: produtosDB ? this.dados : [],
          remove: false,
          backup: produtosDB ? backup : [],
        };
        this.abas.push(objAba);
      } catch (e) {
        this.notification().error({erro: "Erro ao trazer os produtos"});
      } finally {
        this.stopLoader();
      }
    },
    moveDados(dados) {
      if (this.abas[dados.aba]) {
        this.abas[dados.aba].pesquisa = [
          ...dados.items,
          ...this.abas[dados.aba].pesquisa,
        ];
      } else {
        this.abas.push({
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${dayjs()
            .locale("pt-br")
            .format("l")} - ${this.$t("TelaLojas.quantidade")}: ${
            dados.items.length
          }`,
          pesquisa: dados.items,
        });
      }
    },
    async getAutocompletes() {
      this.loadingGrupo = true;
      this.grupoItems = [];
      try {
        let grupoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901001",
          params: {},
        });
        this.grupoItems = grupoDB.database;
        let secaoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901002",
          params: {},
        });
        this.secaoItems = secaoDB.database;
        let deptoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901003",
          params: {},
        });
        this.depItems = deptoDB.database;
        let colecDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901004",
          params: {},
        });
        this.colecItems = colecDB.database;
      } catch (e) {
        this.notification().error({erro: e});
      } finally {
        this.loadingGrupo = false;
      }
    },
    async salvaEdicaoProdutosLinha() {
      let usuario = await this.getUser();
      let valEnt = [];
      this.dados.forEach((p) => {
        if (p.isSelected) {
          p.isSelected = false;
          valEnt.push({
            _rCD_PROD: p.ce110_cd_prod,
            _rDS_PROD: p.ce110_ds_prod,
            _rSKU: p.ce110_cd_sku,
            _rREF: p.ce110_cd_ref,
            _rSITUAC: p.ce110_cd_situac,
            _rCD_GRP: p.ce110_cd_grp,
            _rVL_CUSTO: this.unmaskCurrency(p.ce110_vl_custo),
            _rVL_VENANT: p.ce110_vl_venant ? p.ce110_vl_venant : 0,
            _rVL_VENDA: this.unmaskCurrency(p.ce110_vl_venda),
            _rCD_DEPTO: p.ce110_cd_depto,
            _rCD_SECAO: p.ce110_cd_secao,
            _rCD_COLEC: p.ce110_cd_colec,
            _rNR_FABRIC: p.ce110_nr_fabric,
            _rCD_NCM: p.ce110_cd_ncm,
            _rCD_ORIGEM: p.ce110_cd_origem,
            _rCD_CLASS: p.ce110_cd_class,
            _rCD_EXNCM: p.ce110_cd_exncm,
            _rCD_CATEG: p.ce110_cd_categ,
            _rCD_MARCA: p.ce110_cd_marca,
            _rCD_UNCOM: p.ce110_cd_uncom,
            _rCD_OPESIS: usuario.base.us01_nr_usr,
          });
        }
      });
      try {
        await this.postSP("SP_CE360901006", valEnt);
        this.drawer = false;
        this.notification().success({mensagem:"Produtos salvo com sucesso!"});
      } catch (err) {
        this.notification().error({erro: "Erro ao editar as informações!"});
      }
    },

    async addNovoItem(prod) {
      const usuario = await this.getUser();
      let valEnt = {
        _rCD_PROD: prod.ce110_cd_prod,
        _rDS_PROD: prod.ce110_ds_prod,
        _rSKU: prod.ce110_cd_sku,
        _rREF: prod.ce110_cd_ref,
        _rSITUAC: prod.ce110_cd_situac ? 1 : 2,
        _rCD_GRP: prod.ce113_cd_grp ? prod.ce113_cd_grp : prod.ce110_cd_grp,
        _rVL_CUSTO: this.unmaskCurrency(prod.ce110_vl_custo),
        _rVL_VENDA: this.unmaskCurrency(prod.ce110_vl_venda),
        _rVL_VENANT: prod.ce110_vl_venant ? prod.ce110_vl_venant : 0,
        _rCD_DEPTO: prod.ce175_cd_depto
          ? prod.ce175_cd_depto
          : prod.ce110_cd_depto,
        _rCD_SECAO: prod.ce176_cd_secao
          ? prod.ce176_cd_secao
          : prod.ce110_cd_secao,
        _rCD_COLEC: prod.ce178_cd_colec,
        _rCD_UNCOM: prod.nf722_cd_uncom,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
        _rCD_NCM: prod.nf730_cd_nps,
        _rCD_EXNCM: prod.nf730_cd_exncm,
        _rCD_CLASS: prod.ce110_cd_class,
        _rCD_CATEG: prod.ce177_cd_categ,
        _rCD_MARCA: prod.ce179_cd_marca,
        _rNR_FABRIC: prod.ce115_nr_pessoa,
        _rDS_DET: prod.ce106_ds_det,
        _rDS_ESPTEC: prod.ce106_ds_esptec,
      };
      try {
        await this.postSP("SP_CE360901005", [valEnt]);
        this.notification().success({mensagem:"Informações salvas com sucesso!"});
      } catch (err) {
        this.notification().error({erro: "Erro ao editar as informações!"});
      }
    },
    async editNovoItem(prod) {
      const usuario = await this.getUser();
      let valEnt = {
        _rCD_PROD: prod.ce110_cd_prod,
        _rDS_PROD: prod.ce110_ds_prod,
        _rSKU: prod.ce110_cd_sku,
        _rSITUAC: prod.ce110_cd_situac,
        _rCD_GRP: prod.ce113_cd_grp ? prod.ce113_cd_grp : prod.ce110_cd_grp,
        _rVL_CUSTO: this.unmaskCurrency(prod.ce110_vl_custo),
        _rVL_VENANT: this.unmaskCurrency(prod.ce110_vl_venant),
        _rVL_VENDA: this.unmaskCurrency(prod.ce110_vl_venda),
        _rCD_DEPTO: prod.ce175_cd_depto
          ? prod.ce175_cd_depto
          : prod.ce110_cd_depto,
        _rCD_SECAO: prod.ce176_cd_secao
          ? prod.ce176_cd_secao
          : prod.ce110_cd_secao,
        _rCD_COLEC: prod.ce110_cd_colec,
        _rCD_CLASS: prod.ce110_cd_class,
        _rCD_NCM: prod.nf730_cd_nps,
        _rCD_EXNCM: prod.ce110_cd_exncm,
        _rCD_CATEG: prod.ce110_cd_categ,
        _rCD_MARCA: prod.ce179_cd_marca,
        _rNR_FABRIC: prod.ce115_nr_pessoa,
        _rCD_UNCOM: prod.ce110_cd_uncom,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
        _rDS_DET: prod.ce106_ds_det,
        _rDS_ESPTEC: prod.ce106_ds_esptec,
        _rIMAGENS: prod.imagens,
      };
      try {
        await this.request({ metodo: 'post', sp: "SP_CE360901006", params: valEnt });
        this.notification().success({mensagem:"Informações salvas com sucesso!"});
      } catch (err) {
        this.notification().error({erro: "Erro ao editar as informações!"});
      }
    },
    escolheAba(aba) {
      this.nomeAbaSelecionada = aba;
    },
    aplicarBackup() {
      const backup = this.abas.find(
        (aba) => aba.nome_aba === this.nomeAbaSelecionada,
      ).backup;
      this.dados = backup;
      this.keyFlexGrid++;
    },
    deletarDaTabela(e) {
      this.dados = this.dados.filter((item) => !e.includes(item));
      const selectedPesquisa = this.abas.find(
        (aba) => aba.nome_aba === this.nomeAbaSelecionada,
      );
      selectedPesquisa.pesquisa = this.dados;
      const abasVazias = this.abas.filter((aba) => aba.pesquisa.length === 0);
      this.abas = this.abas.filter((aba) => !abasVazias.includes(aba));

      //this.keyFlexGrid++
    },
  },
};
